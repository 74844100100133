.markdown {
  p {
    margin-bottom: 25px;
  }

  a {
    color: var(--link-normal);

    &:visited {
      color: var(--link-visited);
    }

    &:hover {
      color: var(--link-hover);
    }
  }
}
